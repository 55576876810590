import React, { useState } from "react";
import { faThList } from "@fortawesome/free-solid-svg-icons";
import { Routers } from "../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Image, Nav } from "@themesberg/react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  FaSearch,
  FaComments,
  FaTasks,
  FaListAlt,
  FaCalendarAlt,
  FaUser,
  FaCreditCard,
  FaHeart,
  FaCog,
  FaSignOutAlt,
} from "react-icons/fa";

const CustomerRoutes = ({ closeModal, worker, setShowDefaultCategory }) => {
  const location = useLocation();
  const [adminId, setAdminId] = useState(0);
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      SVG,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };
    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon" style={{ fontSize: "1em" }}>
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}
            {SVG ? (
              <span
                className="sidebar-icon svg-icon"
                style={{ fontSize: "1.3em" }}
              >
                {SVG}
              </span>
            ) : null}
            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };
  return (
    <>
      <NavItem
        title="Dashboard"
        icon={faThList}
        link={Routers.DashboardOverview.path}
      />
      <button
        style={{
          background: "none",
          border: "none",
          textAlign: "left",
          margin: "3px -7px",
        }}
        onClick={(item) => {
          setAdminId(item.id);
        }}
      >
        <NavItem title="Search" SVG={<FaSearch />} link={Routers.Search.path} />
        <NavItem title="Chat" SVG={<FaComments />} link={Routers.Chat.path} />
        <NavItem title={"My Jobs"} SVG={<FaTasks />} link={Routers.Job.path} />
        {worker && (
          <NavItem title="GIGS" SVG={<FaListAlt />} link={Routers.Gigs.path} />
        )}

        <NavItem
          title="Categories"
          SVG={<FaListAlt />}
          link={Routers.BusinessCategory.path}
        />
        <NavItem
          title="Events"
          SVG={<FaCalendarAlt />}
          link={Routers.Events.path}
        />
        {/* <NavItem
          title="My Tikcets"
          SVG={<FaTicketAlt />}
          link={Routers.MyTickets.path}
        /> */}
      </button>
      <NavItem title="Profile" SVG={<FaUser />} link={Routers.Profile.path} />
      <NavItem
        title="Payments Methods"
        SVG={<FaCreditCard />}
        link={Routers.PayementMethod.path}
      />
      <NavItem
        title="Favourites"
        SVG={<FaHeart />}
        link={Routers.Favourites.path}
      />
      <NavItem title="Settings" SVG={<FaCog />} link={Routers.Settings.path} />
      <Nav.Item className="nav-item">
        <Nav.Link
          as="button"
          style={{
            background: "none",
            border: "none",
            textAlign: "left",
            padding: 0,
          }}
          onClick={() => {
            // dispatch(logoutRequest({}));
            setShowDefaultCategory(true);
          }}
        >
          <NavItem title="Logout" SVG={<FaSignOutAlt />} />
        </Nav.Link>
      </Nav.Item>
    </>
  );
};
export default CustomerRoutes;
