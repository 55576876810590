import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Navbar from "../../components/Navbar";
import { useSelector, useDispatch } from 'react-redux';
import { getJobEvents } from '../../Redux/JobManagement/actions';
import { useState, useEffect } from 'react';
import Spinner from "../../components/spinner";

const localizer = momentLocalizer(moment);

const Container = styled.div`
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const StyledCalendar = styled(Calendar)`
    .rbc-toolbar {
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        padding: 10px;
        border-radius: 10px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .rbc-event {
        background-color: #4caf50;
        color: #fff;
        border-radius: 5px;
        padding: 5px;
    }
    .rbc-today {
        background-color: #e3f2fd;
    }
    .rbc-calendar {
        background-color: #4caf50;
        color: #fff;
        border-radius: 5px;
        padding: 5px;
        min-height: 500px;  
    }

    @media (max-width: 768px) {
        .rbc-month-view {
            table {
                width: 100%;
            }
        }
    }
`;

const Events = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.Auther);
    const [events, setEvents] = useState([]);
    const [loader, setLoader] = useState(true); // Added loader state

    useEffect(() => {
        setLoader(true); // Set loader to true before dispatch
        dispatch(getJobEvents({ userId: currentUser.id }));
    }, [dispatch, currentUser.id]);

    const jobEvents = useSelector((state) => state.Job.events);

    useEffect(() => {
        if (jobEvents.length > 0) {
            const formattedEvents = jobEvents.map(event => ({
                id: event.jobs.id,
                title: event.jobs.name || "No Title",
                start: new Date(event.jobs.startDate),
                end: new Date(event.jobs.endDate),
                description: event.jobs.description
            }));
            setEvents(formattedEvents);
        }
        setLoader(false); // Set loader to false after processing events
    }, [jobEvents]);

    const handleSelectEvent = (event) => {
        navigate(`/detailJob/${event.id}`);
    };

    return (
        <>
            <Navbar module={"Events"} />
            <Container>
                {loader ? (
                    <Spinner />
                ) : (
                    <StyledCalendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: "80vh", minHeight: "500px" }}
                        onSelectEvent={handleSelectEvent}
                    />
                )}
            </Container>
        </>
    );
};

export default Events;