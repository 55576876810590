/* eslint-disable */
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row
} from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import { jobById } from '../../Redux/addJob/actions'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { Rating } from 'react-simple-star-rating'
import DetailHeading from '../../components/DetailHeading'
import Dispute from '../../components/Dispute'
import RateModal from '../../components/modal'
import ApplyModal from './workerJob/applymodal'
import {
  deleteAddJob,
  emergencyJob,
  confirmHiring,
  startJob,
  completeJob,
  confirmCompleteByWorker
} from '../../Redux/addJob/actions'
import { hiredApplicant } from '../../Redux/profile/actions'
import Autocomplete from 'react-google-autocomplete'
import Spinner from '../../components/spinner'
// import { getProfile } from "../../Redux/profile/actions";
import { Box } from '@mui/material'

const MyJobDetails = () => {
  const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs'
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useLocation()
  let DisputeId = params?.search.split('?')[1]
  const [hiredId, sethiredId] = useState()
  const [location, setLocation] = useState([])
  const [longitude, setLongitude] = useState()
  const [latitude, setLatitude] = useState()
  const [startDate, setStartDate] = React.useState(new Date())
  const [endDate, setEndDate] = React.useState(new Date())
  let id = params.pathname.split('/')[2]
  let jobId = params.pathname.split('/')[2]
  const newArrivalData = useSelector(state => state?.Seeker?.newArrival?.data)
  // let profileId = params.pathname.split("/")[2];
  const [newArrivalProvider, setNewArrivalProvider] = useState()
  useEffect(() => {
    if (newArrivalData !== undefined) {
      setNewArrivalProvider(newArrivalData)
    }
  }, [newArrivalData])
  const SingleId = useSelector(state => state?.addJob?.jobById)
  const Login = useSelector(state => state?.auth?.Auther)
  const confirmJobData = SingleId?.user_job?.find(
    item => item.users.id === Login.id
  )
  // const HiredID = useSelector((state) => state?.addJob?.jobById?.user_job[0]?.hiredby);
  useEffect(() => {
    if (SingleId !== undefined) {
      let datas = SingleId?.user_job?.find(
        data => data?.hiredBy?.id === Login?.id
      )
      sethiredId(datas)
    }
  }, [SingleId])
  const getById = useSelector(state => state.ProfileReducer.profile)
  const [loader, setLoader] = useState(false)
  const [showDefault, setShowDefault] = useState(false)
  const [rating, setRating] = useState(0) // initial rating value
  const [rate, setRate] = useState()
  const [show, setShow] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [isDisputed, setIsDisputed] = useState(false)
  const [emergency, setEmergency] = useState(false)
  const [isPost, setIsPost] = useState(false)
  const [userId, setUserId] = useState(DisputeId)
  const [reason, setReason] = useState(false)
  const [showDefaultEmergency, setShowDefaultEmergency] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [apply, setApply] = useState(false)
  const [showFields, setShowFields] = useState(false)

  const Auther = useSelector(state => state.auth.Auther)
  const JobRateExist = SingleId?.Job_rate?.filter(
    item => item?.ratedBy && item.ratedBy?.id == Auther?.id
  )

  const handleToggle = () => {
    setShowFields(!showFields)
  }

  useEffect(() => {
    dispatch(jobById({ id: jobId, setLoader: setLoader }))
  }, [jobId])
  const handleDelete = id => {
    dispatch(
      deleteAddJob({
        jobId: jobId,
        setShowDefault: setShowDefault,
        history: navigate
      })
    )
  }

  const handleClose = () => {
    setConfirmModal(false)
  }
  const handlefalse = () => {
    setShowDefault(false)
  }
  const handleEdit = () => {
    // Todo: add check for user type
    if (Auther?.role?.name == 'customer')
      navigate({ pathname: `/updateJob/${jobId}`, state: 'edit' })
    else navigate({ pathname: `/updateGig/${jobId}`, state: 'edit' })
  }
  const handleRepost = () => {
    navigate({ pathname: `/updateJob/${jobId}`, state: 'repost' })
  }
  const handleSendOffer = () => {
    navigate({ pathname: `/updateJob/${jobId}`, state: 'Send Offer' })
  }
  const handleRating = rating => {
    setRating(rating)
  }
  const handleRate = rate => {
    setRate(rate)
  }
  const handleChange = () => {
    dispatch(
      emergencyJob({
        id: jobId,
        userId: Login?.id,
        setShowDefaultEmergency: setShowDefaultEmergency,
        history: navigate
      })
    )
  }
  const handleClick = () => {
    let data = {
      job: jobId,
      providerId: SingleId?.user?.id,
      startDate: startDate,
      endDate: endDate,
      latitude: latitude,
      longitude: longitude,
      location: location,
      setConfirmModal: setConfirmModal,
      setLoader: setLoader
    }
    dispatch(hiredApplicant(data))
  }
  const profileCard = () => {
    return (
      <div className='detailed'>
        <Image
          src={SingleId?.image ? SingleId.image : ''}
          className='navbar-brand-light detailImg'
        />
        <h3 className='mb-1 mt-3'>{SingleId?.name ? SingleId.name : ''}</h3>
        <h5 className='text-gray'>
          {SingleId?.profileType ? SingleId.profileType : ''}
        </h5>
        <Rating
          size={25}
          onClick={handleRating}
          readonly={true}
          allowHover={false}
          initialValue={
            SingleId?.rating ? SingleId?.rating * 20 : '0'
          } /* Available Props */
        />
      </div>
    )
  }
  const handleMove = () => {
    navigate(`/chat?${SingleId?.user?.id}?${SingleId?.user?.firebaseId}`)
  }
  const handleGoBack = () => {
    navigate(-1) // This will go back to the previous page
  }

  const handleStartJob = jobId => {
    dispatch(
      startJob({ jobId: jobId, providerId: Login?.id, jobStatus: 'inprogress' })
    )
  }
  const handleConfirmJob = jobId => {
    dispatch(confirmHiring({ jobId: jobId, seekerId: Login?.id, isOffer: confirmJobData.jobStatus === "job_offer" ? true : false, customerId: confirmJobData ? confirmJobData?.hiredBy?.id : null}))
  }

  const handleComplete = jobId => {
    dispatch(
      startJob({ jobId: jobId, providerId: Login?.id, jobStatus: 'completed' })
    )
  }

  const handleConfirmComplete = jobId => {
    dispatch(
      confirmCompleteByWorker({
        jobId: jobId,
        userId: Login?.id,
        jobStatus: 'completed'
      })
    )
  }

  const handlePlaceSelected = place => {
    const address = place.formatted_address
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()

    setLocation(address)
    setLatitude(lat)
    setLongitude(lng)
  }
// console.log("SingleId", SingleId)
  return (
    <>
      <Navbar module={'Job Detail'} />
      {loader ? (
        <Spinner />
      ) : (
        <>
          <Col xs={12} xl={12} className={'d-flex justify-content-start mb-2'}>
            <svg
              width='11'
              height='16'
              viewBox='0 0 11 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => handleGoBack()}
            >
              <path
                d='M9.79591 14.8435L9.79557 14.8439C9.56284 15.0818 9.2503 15.2 8.93911 15.2C8.65838 15.2 8.37589 15.1036 8.15012 14.9076L8.14971 14.9073L1.18041 8.82491C0.939515 8.61471 0.799112 8.31587 0.799112 7.99906C0.799112 7.68333 0.93963 7.38454 1.18041 7.17445L8.14971 1.09206L8.15005 1.09176C8.62347 0.6805 9.35494 0.706129 9.79539 1.15531L9.79539 1.15531L9.79591 1.15584C10.2386 1.6107 10.2057 2.32402 9.72866 2.74114L9.72851 2.74128L3.7035 7.99908L9.72853 13.2581L9.72866 13.2582C10.2057 13.6753 10.2386 14.3887 9.79591 14.8435Z'
                fill='#3461FD'
                stroke='#3461FD'
                stroke-width='0.4'
              />
            </svg>
          </Col>
          <Row>
            <>
              {SingleId?.user?.id === Login?.id ? (
                <Col lg={4} md={6} xs={12} className='pb-3 mb-3 mt-2'>
                  <Card
                    border='light'
                    className='card-box-shadow py-3 px-4 mb-3'
                  >
                    {profileCard()}
                  </Card>
                </Col>
              ) : (
                hiredId === Login?.id && (
                  <Col lg={4} md={6} xs={12} className='pb-3 mb-3 mt-2'>
                    <Card
                      border='light'
                      className='card-box-shadow py-3 px-4 mb-3'
                    >
                      {profileCard()}
                    </Card>
                  </Col>
                )
              )}

              <Col
                lg={
                  SingleId?.user?.id === Login?.id
                    ? 8
                    : hiredId === Login?.id
                    ? 8
                    : 12
                }
                md={
                  SingleId?.user?.id === Login?.id
                    ? 8
                    : hiredId === Login?.id
                    ? 8
                    : 12
                }
                xs={12}
                className='pb-3 mb-3'
              >
                <Card
                  border='light'
                  className='text-left p-0 mb-4 profileView info p-3 mt-2 mt-2'
                >
                  {SingleId?.user?.id === Login?.id
                    ? ''
                    : hiredId === Login?.id
                    ? ''
                    : profileCard()}

                  <Card.Body className='pb-2 border_bottom mb-1'>
                    <div className='pb-2 d-flex justify-content-between align-items-baseline'>
                      <Card.Title className='text-primary'>
                        User Information
                      </Card.Title>
                    </div>
                    {SingleId?.user?.id === Login?.id ? (
                      <>
                        <DetailHeading
                          heading={'JobRequirement'}
                          value={
                            SingleId?.requirement ? SingleId?.requirement : '-'
                          }
                        />
                        <DetailHeading
                          heading={'ToolsNeeded'}
                          value={
                            SingleId?.toolsNeeded ? SingleId?.toolsNeeded : '-'
                          }
                        />
                      </>
                    ) : (
                      hiredId === Login?.id && (
                        <>
                          <DetailHeading
                            heading={'JobRequirement'}
                            value={
                              SingleId?.requirement
                                ? SingleId?.requirement
                                : '-'
                            }
                          />
                          <DetailHeading
                            heading={'ToolsNeeded'}
                            value={
                              SingleId?.toolsNeeded
                                ? SingleId?.toolsNeeded
                                : '-'
                            }
                          />
                        </>
                      )
                    )}

                    <DetailHeading
                      heading={'Payment Type'}
                      value={
                        SingleId?.paymentType ? SingleId?.paymentType : '-'
                      }
                    />
                    <DetailHeading
                      heading={'Rate'}
                      value={`$ ${
                        SingleId?.rate ? SingleId?.rate : SingleId?.rate
                      }`}
                    />
                    <DetailHeading
                      heading={'TimeRequired'}
                      value={`${SingleId?.days} Days and ${SingleId?.hours} hours`}
                    />
                    <DetailHeading
                      heading={'Job Type'}
                      value={
                        SingleId?.jobType
                          ? SingleId?.jobType?.name === 'Permanent'
                            ? 'Full-Time'
                            : SingleId?.jobType?.name
                          : '-'
                      }
                    />
                    <DetailHeading
                      heading={'Service Location'}
                      value={SingleId?.jobPlace ? SingleId?.jobPlace : '-'}
                    />
                    <DetailHeading
                      heading={'Job Nature'}
                      value={
                        SingleId?.jobNature ? SingleId?.jobNature?.name : '-'
                      }
                    />
                    <DetailHeading
                      heading={'Location'}
                      value={SingleId?.location ? SingleId?.location?.[0] : '-'}
                    />
                    {SingleId?.unit && (
                      <DetailHeading
                        heading={'Unit No '}
                        value={SingleId?.unit ? SingleId?.unit : '-'}
                      />
                    )}

                    {SingleId?.user?.id === Login?.id ? (
                      <>
                        <DetailHeading
                          heading={'Providers Required'}
                          value={
                            SingleId?.noOfProviders
                              ? SingleId.noOfProviders
                              : '-'
                          }
                        />
                        <DetailHeading
                          heading={'Experience Required'}
                          value={
                            SingleId?.experienceRequired
                              ? SingleId.experienceRequired
                              : '-'
                          }
                        />
                      </>
                    ) : (
                      hiredId === Login?.id && (
                        <>
                          <DetailHeading
                            heading={'Providers Required'}
                            value={
                              SingleId?.noOfProviders
                                ? SingleId.noOfProviders
                                : '-'
                            }
                          />
                          <DetailHeading
                            heading={'Experience Required'}
                            value={
                              SingleId?.experienceRequired
                                ? SingleId.experienceRequired
                                : '-'
                            }
                          />
                        </>
                      )
                    )}
                    {/* </>
                )} */}
                  </Card.Body>
                  {SingleId?.user?.id === Login?.id ? (
                    <>
                      {/* <Card.Body className="pb-2 border_bottom mb-1 d-flex justify-content-between align-items-baseline">
                    <Dropdown.Item onClick={() => setReason(true)}>Dispute</Dropdown.Item>
                  </Card.Body> */}
                      <Link
                        className='text-white fw-bold'
                        to={`/Applicants/${jobId}`}
                      >
                        <Card.Body className='pb-2 border_bottom mb-1 d-flex justify-content-between align-items-baseline'>
                          <Card.Text className='text-black mb-2'>
                            Job Applicants
                          </Card.Text>
                        </Card.Body>
                      </Link>
                      <Link
                        className='text-white fw-bold'
                        to={`/LogHours/${jobId}`}
                      >
                        <Card.Body className='pb-2 border_bottom mb-1 d-flex justify-content-between align-items-baseline'>
                          <Card.Text className='text-black mb-2'>
                            Logged Hours
                          </Card.Text>
                        </Card.Body>
                      </Link>
                      <Link
                        className='text-white fw-bold'
                        to={`/extendTime/${jobId}`}
                      >
                        <Card.Body className='pb-2 border_bottom mb-1 d-flex justify-content-between align-items-baseline'>
                          <Card.Text className='text-black mb-2'>
                            Extend Time Request
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </>
                  ) : hiredId === Login?.id ? (
                    <>
                      <Link
                        className='text-white fw-bold'
                        to={`/Applicants/${jobId}`}
                      >
                        <Card.Body className='pb-2 border_bottom mb-1 d-flex justify-content-between align-items-baseline'>
                          <Card.Text className='text-black mb-2'>
                            Job Applicants
                          </Card.Text>
                        </Card.Body>
                      </Link>
                      <Link
                        className='text-white fw-bold'
                        to={`/LogHours/${jobId}`}
                      >
                        <Card.Body className='pb-2 border_bottom mb-1 d-flex justify-content-between align-items-baseline'>
                          <Card.Text className='text-black mb-2'>
                            Logged Hours
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </>
                  ) : (
                    <>
                      {/* {loader ? (
                    <Spinner />
                  ) : (
                    <> */}
                      <Box>
                        {SingleId?.createdBy === 'customer' ? (
                          <Box class='d-grid gap-2 col-3 mx-auto my-2'>
                            <>
                              {confirmJobData === undefined ? (
                                <Button
                                  variant='primary'
                                  color='dark'
                                  size='lg'
                                  className='mt-2 me-1'
                                  onClick={() => {
                                    setApply(true)
                                  }}
                                >
                                  Apply Now
                                </Button>
                              ) : (
                                <>
                                  {confirmJobData.isAccepted &&
                                  confirmJobData.isConfirmed ? (
                                    <>
                                      {confirmJobData?.completedByProvider ? (
                                        <Button
                                          variant='primary'
                                          color='dark'
                                          size='lg'
                                          className='mt-2 me-1'
                                          disabled={
                                            JobRateExist &&
                                            JobRateExist?.length > 0
                                          }
                                          onClick={() => {
                                            setShow(true)
                                          }}
                                        >
                                          Rate Customer
                                        </Button>
                                      ) : (
                                        <>
                                          {confirmJobData?.jobStatus ===
                                          'upcoming' ? (
                                            <Button
                                              variant='primary'
                                              color='dark'
                                              size='lg'
                                              className='mt-2 me-1'
                                              onClick={() => {
                                                handleStartJob(jobId)
                                              }}
                                            >
                                              Start job
                                            </Button>
                                          ) : (
                                            <>
                                              {SingleId.paymentType ===
                                                'hourly' && (
                                                <Box class='col-12 mx-auto text-center my-2'>
                                                  <Link
                                                    to={`/LogHours/${jobId}`}
                                                  >
                                                    log hours
                                                  </Link>
                                                </Box>
                                              )}
                                              <Button
                                                variant='primary'
                                                color='dark'
                                                size='lg'
                                                className='mt-2 me-1'
                                                onClick={() => {
                                                  confirmJobData.completedBySeeker ==
                                                  true
                                                    ? handleConfirmComplete(
                                                        jobId
                                                      )
                                                    : handleComplete(jobId)
                                                }}
                                              >
                                                {confirmJobData.completedBySeeker
                                                  ? 'Confirm Completed'
                                                  : 'Complete Job'}
                                              </Button>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {confirmJobData.isConfirmed ? (
                                        <>
                                          <Button
                                            variant='primary'
                                            color='dark'
                                            size='lg'
                                            className='mt-2 me-1'
                                            onClick={() => {
                                              handleStartJob(jobId)
                                            }}
                                          >
                                            Start job
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          {confirmJobData.isAccepted ===
                                          false ? (
                                            <Button
                                              variant='primary'
                                              color='dark'
                                              size='lg'
                                              className='mt-2 me-1'
                                            >
                                              Application Rejected
                                            </Button>
                                          ) : confirmJobData.isAccepted ===
                                            null ? (
                                            <Button
                                              variant='primary'
                                              color='dark'
                                              size='lg'
                                              className='mt-2 me-1'
                                            >
                                              Applied
                                            </Button>
                                          ) : (
                                            <Button
                                              variant='primary'
                                              color='dark'
                                              size='lg'
                                              className='mt-2 me-1'
                                              onClick={() => {
                                                handleConfirmJob(jobId)
                                              }}
                                            >
                                              Confirm Job
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          </Box>
                        ) : (
                          <Box class='d-grid gap-2 col-3 mx-auto my-2'>
                            <Button
                              variant='primary'
                              color='dark'
                              size='lg'
                              className='mt-2 me-1'
                              onClick={() => {
                                setConfirmModal(true)
                              }}
                            >
                              Hire Now
                            </Button>
                            <Box class='col-12 mx-auto text-center my-2'>
                              <Link
                                to={`/detailProvider/${SingleId?.user?.id}`}
                              >
                                View Profile
                              </Link>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      {/* </>
                  )} */}
                    </>
                  )}
                </Card>
                {SingleId?.user?.id === Login?.id && (
                  <>
                    {SingleId?.status === 'completed' ||
                    SingleId?.status === 'inprogress' ||
                    SingleId?.status === 'upcoming' ? (
                      <>
                        <div class='float-end'>
                          <Button
                            variant='primary'
                            color='dark'
                            size='lg'
                            className='mt-2 me-1'
                            onClick={handleRepost}
                          >
                            Repost Job
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div>
                        <div class='float-end'>
                          {SingleId?.status === 'Accepted' ||
                          SingleId?.status === 'canceled' ? (
                            <>
                              <Button
                                variant='primary'
                                color='dark'
                                size='lg'
                                className='mt-2 me-1'
                                onClick={handleEdit}
                              >
                                Repost
                              </Button>
                              {SingleId?.isEmergency === true ? (
                                <>
                                  <Button
                                    variant='primary'
                                    color='dark'
                                    size='lg'
                                    className='mt-2 me-1'
                                  >
                                    Emergency Posted
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant='primary'
                                    color='dark'
                                    size='lg'
                                    className='mt-2 me-1'
                                    onClick={() => {
                                      // setShowDefaultEmergency(true);
                                      setEmergency(true)
                                      setIsPost(false)
                                      handleChange()
                                    }}
                                  >
                                    Emergency Post
                                  </Button>
                                </>
                              )}
                            </>
                          ) : (
                            <Button
                              variant='primary'
                              color='dark'
                              size='lg'
                              className='mt-2 me-1'
                              onClick={handleEdit}
                            >
                              Edit Job
                            </Button>
                          )}
                        </div>
                        {SingleId?.status !== 'Accepted' && (
                          <div class='float-end'>
                            <Button
                              variant='primary'
                              color='dark'
                              size='lg'
                              className='mt-2 me-1'
                              onClick={() => {
                                // setAdminId(item.id)
                                setShowDefault(true)
                              }}
                            >
                              Delete Job
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </Col>
            </>
          </Row>
        </>
      )}
      <Dispute setReason={setReason} reason={reason} id={userId} />
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handlefalse}>
        <Modal.Header>
          <Modal.Title className='h5'>Delete Job</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handlefalse} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>Are you sure you want to remove this job ?</Form.Group>
            <Form.Group>
              <div class='d-grid gap-2 col-4 text-center mt-3 mx-auto'>
                <Button
                  variant='primary'
                  onHide={handlefalse}
                  color='dark'
                  size='sm'
                  onClick={() => {
                    handleDelete()
                  }}
                >
                  Delete
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      {show && (
        <RateModal
          show={show}
          setShow={setShow}
          img={SingleId?.image ? SingleId.image : ''}
          jobId={jobId}
          userId={SingleId?.user?.id}
          ratedTo={SingleId?.user?.id}
          ratedBy={Auther?.id}
          setIsCompleted={setIsCompleted}
          setIsDisputed={setIsDisputed}
          isCompleted={isCompleted}
          isDisputed={isDisputed}
        />
      )}
      {apply && (
        <ApplyModal
          apply={apply}
          setApply={setApply}
          img={SingleId?.image ? SingleId.image : ''}
          jobId={jobId}
          userId={SingleId?.user?.id}
        />
      )}
      <Modal
        as={Modal.Dialog}
        centered
        show={confirmModal}
        onHide={handlefalse}
      >
        <Modal.Header>
          <Modal.Title className='h5 text-align-center'>
            Confirmation
          </Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <h4 style={{ marginLeft: '30%' }}>You will be charged</h4>
              <h4
                style={{ color: 'blue', marginLeft: '38%', marginTop: '20px' }}
              >
                {`$${SingleId?.rate} ${
                  SingleId?.paymentType == 'fixed'
                    ? 'for Complete Job'
                    : 'per Hour'
                } `}
              </h4>
            </Form.Group>
            <Form.Group>
              {/* <div class="d-grid gap-2 col-4 text-center mt-2 mx-auto">
                <Button
                  variant="primary"
                  onHide={handleClose}
                  color="dark"
                  size="sm"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </div> */}
              {/* <div className='d-flex justify-content-between align-items-center'>
                <span>Do you want to change location or time?</span>
                <Form.Check
                  type='switch'
                  id='custom-switch'
                  onChange={handleToggle}
                />
              </div> */}
              {/* {showFields && (
                <>
                  <Col md={12} className='mb-3'>
                    <Form.Group controlId='startDate'>
                      <Form.Label>Start date</Form.Label>
                      <DatePicker
                        selected={startDate}
                        label='startDate'
                        name='startDate'
                        style={{ width: '100%' }}
                        value={startDate}
                        onChange={newValue => {
                          setStartDate(newValue)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className='mb-3'>
                    <Form.Group controlId='endDate'>
                      <Form.Label>End date</Form.Label>
                      <DatePicker
                        style={{ width: '100%' }}
                        selected={endDate}
                        label='endDate'
                        name='endDate'
                        value={endDate}
                        onChange={newValue => {
                          setEndDate(newValue)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className='mb-3'>
                    <Form.Label>Location</Form.Label>
                    <Autocomplete
                      apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                      onPlaceSelected={handlePlaceSelected}
                      types={['address']}
                      fields={['formatted_address', 'geometry']}
                      defaultValue={location}
                      style={{ width: '100%' }}
                      className='form-control'
                      // className="pac-container"
                    />
                  </Col>
                </>
              )} */}
              <div class='d-grid gap-2 col-4 text-center mt-3 mx-auto'>
                <Button
                  variant='primary'
                  onHide={handleClose}
                  color='dark'
                  size='sm'
                  onClick={() => {
                    handleClick()
                    handleClose()
                  }}
                >
                  Confirm
                </Button>
              </div>
              {/* {!showFields && (
                <div class='d-grid gap-2 col-4 text-center mt-2 mx-auto'>
                  <Button
                    variant='primary'
                    onHide={handleClose}
                    color='dark'
                    size='sm'
                    onClick={() => {
                      handleClose()
                      handleSendOffer()
                    }}
                  >
                    Edit Details
                  </Button>
                </div>
              )}  */}
              <h6
                style={{ marginTop: '20px', marginLeft: '20px' }}
                onClick={handleMove}
              >
                If you want any change in days or amount don't hesitate to{' '}
                <a style={{ color: 'blue', cursor: 'pointer' }}> contact me.</a>
              </h6>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default MyJobDetails
