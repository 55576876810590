/* eslint-disable */
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Carousel,
  Image,
  Row,
} from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"; 
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import profile from "../../assets/img/upload.png";
// import AddCategory from "../../components/addCategory";
import {
  addFormJob,
  emergencyJob,
  jobById,
  updateJob,
  sendOfferJob,
} from "../../Redux/addJob/actions";
import { getCategoryList, getSingleList } from "../../Redux/Category/actions";
import { getScheduleDays } from "../../Redux/addJob/actions";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const GeneralInfoForm = () => {
  const iconStyle = {
    backgroundColor: "black",
    width: '50px',
    borderRadius: "50%", // Optional: make the background circular
    padding: "5px", // Optional: add some padding
    color: "white", // Icon color
    cursor: "pointer", // Change cursor to pointer
  };
  const provide = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
  ];
  const experienced = [
    { value: "Entry", label: "Entry" },
    { value: "professional", label: "professional" },
    { value: "Expert", label: "Expert" },
  ];
  const [isPost, setIsPost] = useState(false);
  // const [emergency, setEmergency] = useState(false);
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";
  const params = useLocation();
  let id = params.pathname.split("/")[2];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subCategoryData = useSelector(
    (state) => state?.Category?.SingleList?.sub_Categories
  );
  const activeForm = params?.location?.state;
  const CategoryData = useSelector((state) => state?.Category?.getCategoryList);
  const SingleId = useSelector((state) => state?.addJob?.jobById);
  const getLogDays = useSelector((state) => state?.addJob?.getLogDays);
  let str =
    SingleId?.category?.length > 0 ? SingleId?.category[0]?.category : "false";
  // const [selectedItem, setSelectedItem] = useState(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const [hours, setHours] = useState("1");
  const [days, setDays] = useState("1");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [logDays, setLogDays] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const options = getLogDays?.map((day) => ({
    label: day.day,
    value: day.id,
  }));
  const handleSelectChange = (selectedOptions) => {
    setSelectedDays(
      selectedOptions.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
    const selectedIds = selectedOptions.map((option) => option.value);
    setLogDays(selectedIds);
  };
  const [providers, setProviders] = useState();
  // eslint-disable-next-line
  const [showDefaultEmergency, setShowDefaultEmergency] = useState(false);
  // provide.filter((option) => option.label == SingleId.noOfProviders)
  const [experience, setExperience] = useState();
  const [jobType, setJobType] = useState(
    SingleId?.jobType?.name ? SingleId?.jobType?.name : "Part-time"
  );
  const [jobPlace, setJobPlace] = useState(
    SingleId?.jobPlace ? SingleId?.jobPlace : "Business Location"
  );
  const [onGoing, setOngoing] = useState(false);
  const [paymentType, setPaymentType] = useState(
    SingleId?.paymentType ? SingleId?.paymentType : "hourly"
  );
  const [jobNature, setJobNature] = useState(
    SingleId?.jobNature ? SingleId?.jobNature : "one-time"
  );
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [location, setLocation] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [postItem, setPostItem] = useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  // const [adminId, setAdminId] = useState(0);
  // eslint-disable-next-line
  const [rate, setRate] = useState("");
  const [longitude, setLogintude] = useState();
  const [latitude, setLatitude] = useState();
  // eslint-disable-next-line
  const [unit, setUnit] = useState();
  // eslint-disable-next-line
  const [postJob, setPostJob] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  // eslint-disable-next-line
  const [isOffer, setIsOffer] = useState(true);
  const [loader, setLoader] = useState(false);
  const [buttonDisable, setButtonDisabled] = useState(false);
  let jobId = params.pathname.split("/")[2];
  useEffect(() => {
    setPaymentType(SingleId?.paymentType ? SingleId?.paymentType : "hourly");
    setJobType(SingleId?.jobType?.name ? SingleId?.jobType?.name : "Part-time");
    setJobPlace(SingleId?.jobPlace ? SingleId?.jobPlace : "Business Location");
    setJobNature(
      SingleId?.jobNature?.name ? SingleId?.jobNature?.name : "one-time"
    );
    setUnit(SingleId?.unit ? SingleId?.unit : "");
    setRate(SingleId?.rate ? SingleId?.rate : "");
    // setSelectedImage(SingleId?.image);
    setProviders(
      provide.filter((option) => option.value == SingleId?.noOfProviders)
    );
    setExperience(
      experienced.filter(
        (option) => option.label == SingleId?.experienceRequired
      )
    );
    setLocation(SingleId?.location ? SingleId?.location[0] : "");
    setLatitude(SingleId?.latitude ? SingleId?.latitude : "");
    setLogintude(SingleId?.longitude ? SingleId?.longitude : "");
    setSubCategories({
      value: [
        {
          id: SingleId?.category?.id,
          title: SingleId?.category?.title,
          details: str.details,
        },
      ],
      label: SingleId?.category?.title,
    });
    setStartDate(
      SingleId?.startDate ? new Date(SingleId?.startDate) : new Date()
    );
    setEndDate(SingleId?.endDtae ? new Date(SingleId?.endDtae) : new Date());
    setOngoing(SingleId?.isOngoing ? SingleId?.isOngoing : false);
    setStartTime(SingleId?.startTime ? SingleId?.startTime : "");

    setEndTime(SingleId?.endTime ? SingleId?.endTime : "");
    if (SingleId?.jobDays) {
      setSelectedDays(
        SingleId?.jobDays?.map((option) => ({
          label: option.day,
          value: option.id,
        }))
      );
    }
    const selectedIds = SingleId?.jobDays?.map((option) => option.id);
    setLogDays(selectedIds);
  }, [SingleId]);
  useEffect(() => {
    if (categories?.value[0].id !== undefined) {
      dispatch(
        getSingleList({
          id: categories?.value[0].id,
          search: "",
        })
      );
    }
  }, [categories, dispatch]);
  console.log(location, "location", latitude, longitude);
  useEffect(() => {
    dispatch(getScheduleDays());
    dispatch(
      getCategoryList({
        search: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let array = [];
    subCategoryData?.map((item) => {
      array.push({
        value: [{ id: item?.id, title: item?.title, details: item?.details }],
        label: item?.title,
      });
    });
    setSubCategoryList(array);
  }, [subCategoryData]);

  useEffect(() => {
    let array = [];
    CategoryData?.map((item) => {
      array.push({
        value: [{ id: item?.id, title: item?.title, details: item?.details }],
        label: item?.title,
      });
    });
    setCategoryList(array);
  }, [CategoryData]);

  const inputEl = useRef(null);
  const onButtonClick = () => {
    inputEl.current.click();
  };
  const CategorySchema = Yup.object().shape({
    jobName: Yup.string().trim().required("Job Name is required"),
    description: Yup.string().trim().required("description is required"),
    jobRequirements: Yup.string().trim().required("Requirements is required"),
    toolsNeeded: Yup.string().trim().required("Tools is required"),
    rate: Yup.string()
      .required("rate is required")
      // .matches(/^[1-9]+[0-9]*$/, {
      //   message: 'Rate should be Greater 0',
      //   excludeEmptyString: true
      // })
      // .max(8, "Number should not exceed 6 digits")
      .min(1, "Number should not be less than 0"),
    // unit: Yup.string().trim().required("Unit Number is required"),
  });

  const CategoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: SingleId?.id ? SingleId?.id : "",
      jobName: SingleId?.name ? SingleId?.name : "",
      description: SingleId?.description ? SingleId?.description : "",
      jobRequirements: SingleId?.requirement ? SingleId?.requirement : "",
      toolsNeeded: SingleId?.toolsNeeded ? SingleId?.toolsNeeded : "",
      rate: SingleId?.rate ? SingleId?.rate : "--",
      unit: SingleId?.unit ? SingleId?.unit : "",
      onGoing: SingleId?.isOngoing ? SingleId?.isOngoing : false,
      jobType: SingleId?.jobType ? SingleId?.jobType : "",
      jobPlace: SingleId?.jobPlace ? SingleId?.jobPlace : "",
      jobImg: SingleId?.image ? SingleId?.image : "",
      paymentType: SingleId?.paymentType ? SingleId?.paymentType : "",
      isPost: SingleId?.isPost ? SingleId?.isPost : "",
      jobNature: SingleId?.jobNature ? SingleId?.jobNature : "",
      startDate: SingleId?.startDate ? SingleId?.startDate : "",
      endDate: SingleId?.endDate ? SingleId?.endDate : null,
      location: SingleId?.location ? SingleId?.location : "",
      latitude: SingleId?.latitude ? SingleId?.latitude : "",
      longitude: SingleId?.longitude ? SingleId?.longitude : "",
      days: SingleId?.days ? SingleId?.days : "",
      hours: SingleId?.hours ? SingleId?.hours : "",

      logDays: SingleId?.logDays ? SingleId?.logDays : "",
      startTime: SingleId?.startTime ? SingleId?.startTime : "",
      endTime: SingleId?.endTime ? SingleId?.endTime : "",

      providers: SingleId?.noOfProviders ? SingleId?.noOfProviders : "",
      experience: SingleId?.experienceRequired
        ? SingleId?.experienceRequired
        : "",
      remember: true,
    },
    validationSchema: CategorySchema,
    onSubmit: async (values, action) => {
      setShowDefaultEmergency(true);
      setPostJob(true);
      const formattedStartDate = `"${format(
        startDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      const formattedEndDate = `"${format(
        endDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      let data = {
        id: values.id,
        name: values.jobName,
        description: values.description,
        requirement: values.jobRequirements,
        paymentType: paymentType,
        rate: values.rate,
        unit: values.unit,
        location: location,
        longitude: longitude,
        latitude: latitude,
        noOfProviders: providers.value ? providers.value : providers[0].value,
        toolsNeeded: values.toolsNeeded,
        experienceRequired:
          Array.isArray(experience) === true
            ? experience[0].value
            : experience.value,
        jobNature: jobNature,

        startDate: formattedStartDate,
        endDate: onGoing ? "" : formattedEndDate,
        jobType: jobType,
        category: subCategories.value[0].id,
        jobImg: selectedImage ? selectedImage : null,
        image: SingleId?.image ? SingleId?.image : null,
        logDays: logDays ? logDays : "",
        startTime: JSON.stringify(startTime),
        endTime: JSON.stringify(endTime),
        days: days,
        hours: hours,
        jobPlace: jobPlace,
        isOngoing: onGoing,
        setReset: action.resetForm,
        setShowDefault: setShowDefault,
        showDefault: showDefault,
        setSelectedImage: setSelectedImage,
        history: navigate,
        existImg: SingleId?.image,
        isPost: isPost,
        setPostJob: setPostJob,
        setButtonDisabled: setButtonDisabled,
        isOffer: isOffer,
        serviceId: jobId,
      };
      if (!id) {
        dispatch(addFormJob(data));
      } else {
        if (postItem) {
          if (isPost) {
            setButtonDisabled(true);
            setShowDefaultEmergency(true);
            dispatch(addFormJob(data));
          } else {
            dispatch(
              emergencyJob({
                id: jobId,
                setShowDefaultEmergency: setShowDefaultEmergency,
                history: navigate,
              })
            );
          }
        }
        // if (!isPost) {
        if (activeButton == "update") {
          dispatch(updateJob(data, id));
        }
        // }
        if (activeButton == "send offer") {
          dispatch(sendOfferJob(data, jobId));
        }
      }
    },
  });
  useEffect(() => {
    if (jobId !== undefined) {
      dispatch(jobById({ id: jobId, setLoader: setLoader }));
    }
  }, [jobId]);

  // Add location
  const [showDefault, setShowDefault] = useState(false);

  const imageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setSelectedImage(filesArray); // Update to handle multiple images
    }
  };
  const { ref } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
      setLogintude(place.geometry.location.lng());
      setLatitude(place.geometry.location.lat());
    },
    options: {
      types: ["(regions)"],
    },
    defaultValue: location,
  });
  const PreventFirstZero = (e) => {
    if (e.target.value.length == 0 && e.which == "0".charCodeAt(0)) {
      e.preventDefault();
      return false;
    }
  };
  const handleInputChange = (e) => {
    setLocation(e.target.value);
  };

  return (
    <>
      <Col className={"d-flex justify-content-center"}>
        <Carousel
          controls={selectedImage?.length > 1}
          interval={null}
          style={{ width: "150px" }}
          indicators={false}
          prevIcon={
            <div style={iconStyle}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          }
          nextIcon={
            <div style={iconStyle}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          } // Use styled icons
        >
          {selectedImage?.length > 0 ? (
            selectedImage.map(
              (
                item,
                index // Map through multiple images
              ) => (
                <Carousel.Item key={index}>
                  <Image
                    src={URL.createObjectURL(item)}
                    onClick={onButtonClick}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                    className="navbar-brand-light"
                  />
                </Carousel.Item>
              )
            )
          ) : (
            <Carousel.Item>
              <img
                src={SingleId?.image ? SingleId.image : profile}
                alt="60px"
                width={"130px"}
                onClick={onButtonClick}
                style={{ cursor: "pointer", transition: "transform 0.5s ease" }}
              />
            </Carousel.Item>
          )}
        </Carousel>
        <Form.Control
          accept="image/*"
          type="file"
          id="file"
          name="file"
          onChange={imageChange}
          className="d-none"
          ref={inputEl}
          multiple
        />
      </Col>
      <Card className="bg-white shadow-sm mb-4 border-0">
        <Card.Body>
          <Form onSubmit={CategoryFormik.handleSubmit}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="jobName">
                  <Form.Label>Job Name</Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    disabled={activeForm == "Send Offer" ? true : false}
                    placeholder="Enter your Job name"
                    value={CategoryFormik.values.jobName}
                    name="jobName"
                    label="jobName"
                    onChange={(e) => {
                      CategoryFormik.setFieldValue("jobName", e.target.value);
                    }}
                  />
                  {CategoryFormik.touched.jobName &&
                  CategoryFormik.errors.jobName ? (
                    <div style={{ color: "red" }}>
                      {CategoryFormik.errors.jobName}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              {/* {paymentType !== "fixed" && ( */}
              <Col md={6} className="mb-3">
                <Form.Group id="jobNature">
                  <Form.Label>Job Nature</Form.Label>
                  <fieldset className="d-flex radioButton">
                    <Form.Check
                      // defaultChecked
                      checked={jobNature == "one-time"}
                      type="radio"
                      label="One-time"
                      value="one-time"
                      name="jobNature"
                      className="radio1"
                      Description
                      onChange={(event) => {
                        setJobNature(event.target.value);
                      }}
                    />

                    <Form.Check
                      checked={jobNature == "recurring"}
                      type="radio"
                      label="Recurring"
                      value="recurring"
                      name="jobNature"
                      onChange={(event) => {
                        setJobNature(event.target.value);
                      }}
                    />
                  </fieldset>
                </Form.Group>
              </Col>
              {/* )} */}
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    disabled={activeForm == "Send Offer" ? true : false}
                    rows="3"
                    placeholder="Description"
                    value={CategoryFormik.values.description}
                    name="description"
                    label="description"
                    onChange={(e) => {
                      CategoryFormik.setFieldValue(
                        "description",
                        e.target.value
                      );
                    }}
                  />
                  {CategoryFormik.touched.description &&
                  CategoryFormik.errors.description ? (
                    <div style={{ color: "red" }}>
                      {CategoryFormik.errors.description}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Number of Providers required</Form.Label>
                  <Select
                    // defaultValue={CategoryFormik.values.providers}
                    value={providers}
                    // value="2"
                    onChange={setProviders}
                    options={provide}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="jobRequirements">
                  <Form.Label>Job Requirements</Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Job Requirements..."
                    value={CategoryFormik.values.jobRequirements}
                    name="jobRequirements"
                    label="jobRequirements"
                    onChange={(e) => {
                      CategoryFormik.setFieldValue(
                        "jobRequirements",
                        e.target.value
                      );
                    }}
                  />
                  {CategoryFormik.touched.jobRequirements &&
                  CategoryFormik.errors.jobRequirements ? (
                    <div style={{ color: "red" }}>
                      {CategoryFormik.errors.jobRequirements}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Group id="toolsNeeded">
                  <Form.Label>Tools Needed</Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Tools needed..."
                    value={CategoryFormik.values.toolsNeeded}
                    name="toolsNeeded"
                    label="toolsNeeded"
                    onChange={(e) => {
                      CategoryFormik.setFieldValue(
                        "toolsNeeded",
                        e.target.value
                      );
                    }}
                  />
                  {CategoryFormik.touched.toolsNeeded &&
                  CategoryFormik.errors.toolsNeeded ? (
                    <div style={{ color: "red" }}>
                      {CategoryFormik.errors.toolsNeeded}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {paymentType === "fixed" ? (
                <Col md={6} className="mb-3">
                  <Form.Group id="PaymentType">
                    <Form.Label>Payment Type</Form.Label>
                    <fieldset className="d-flex radioButton">
                      <Form.Check
                        // defaultChecked
                        type="radio"
                        checked={paymentType === "hourly"}
                        // defaultValue=""
                        label="Hourly"
                        value="hourly"
                        name="paymentType"
                        className="radio1"
                        onChange={(event) => {
                          setPaymentType(event.target.value);
                        }}
                      />
                      <Form.Check
                        type="radio"
                        checked={paymentType == "fixed"}
                        label="Fixed (per job)"
                        value="fixed"
                        name="paymentType"
                        onChange={(event) => {
                          setPaymentType(event.target.value);
                        }}
                      />
                    </fieldset>
                  </Form.Group>
                </Col>
              ) : (
                <Col md={6} className="mb-3">
                  <Form.Group id="PaymentType">
                    <Form.Label>Payment Type</Form.Label>
                    <fieldset className="d-flex radioButton">
                      <Form.Check
                        // defaultChecked
                        type="radio"
                        checked={paymentType === "hourly"}
                        // defaultValue=""
                        label="Hourly"
                        value="hourly"
                        name="paymentType"
                        className="radio1"
                        onChange={(event) => {
                          setPaymentType(event.target.value);
                        }}
                      />
                      <Form.Check
                        type="radio"
                        checked={paymentType === "fixed"}
                        label="Fixed"
                        value="fixed"
                        name="paymentType"
                        onChange={(event) => {
                          setPaymentType(event.target.value);
                        }}
                      />
                    </fieldset>
                  </Form.Group>
                </Col>
              )}

              <Col md={6} className="mb-3">
                <Form.Group id="jobType">
                  <Form.Label>Job Type</Form.Label>
                  <fieldset className="d-flex radioButton">
                    <Form.Check
                      // defaultChecked
                      type="radio"
                      checked={jobType === "Part-time"}
                      label="Part-time"
                      name="jobType"
                      value="Part-time"
                      className="radio1"
                      onChange={(event) => {
                        setJobType(event.target.value);
                      }}
                    />
                    <Form.Check
                      type="radio"
                      checked={jobType === "Permanent"}
                      label="Full-time"
                      name="jobType"
                      value="Permanent"
                      onChange={(event) => {
                        setJobType(event.target.value);
                      }}
                    />
                  </fieldset>
                </Form.Group>
              </Col>

              <Row className="w-100">
                {jobNature !== "recurring" && (
                  <Col md={6} className="mb-1">
                    <Row className="w-100">
                      <Col md={6} className="mb-1">
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker
                          selected={startDate}
                          label="startDate"
                          name="startDate"
                          value={startDate}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                        />
                      </Col>
                      {!onGoing && (
                        <Col md={6} className="mb-1">
                          <Form.Label>End Date</Form.Label>
                          <DatePicker
                            selected={endDate}
                            label="endDate"
                            name="endDate"
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
                <Col md={6} className="mb-1">
                  <Row className="w-100">
                    <Col md={6} className="mb-1">
                      <Form.Label>Start Time </Form.Label>
                      <TimePicker
                        disableClock={true}
                        name="startTime"
                        value={startTime}
                        // format="HH:mm:ss"
                        onChange={(newValue) => {
                          setStartTime(newValue);
                        }}
                      />
                    </Col>
                    <Col md={6} className="mb-1">
                      <Form.Label>End Time </Form.Label>
                      <TimePicker
                        disableClock="true"
                        name="endTime"
                        value={endTime}
                        // format="HH:mm:ss"
                        onChange={(newValue) => {
                          setEndTime(newValue);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                {jobNature == "recurring" && (
                  <Col md={6} className="mb-3">
                    <Form.Group id="Unit Number">
                      <Form.Label>Select Days</Form.Label>
                      <Select
                        options={options}
                        isMulti
                        value={selectedDays}
                        onChange={handleSelectChange}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Col md={6} className="mb-3">
                <Form.Group id="fixedRate">
                  <Form.Label>{`${paymentType} Rate`}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="$"
                    value={CategoryFormik.values.rate}
                    name="rate"
                    label="rate"
                    onKeyPress={(event) => PreventFirstZero(event)}
                    onChange={(e) => {
                      CategoryFormik.setFieldValue("rate", e.target.value);
                    }}
                  />
                  {CategoryFormik.touched.rate && CategoryFormik.errors.rate ? (
                    <div style={{ color: "red" }}>
                      {CategoryFormik.errors.rate}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="Unit Number">
                  <Form.Label>Unit Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Unit number"
                    value={CategoryFormik.values.unit}
                    name="unit"
                    label="unit"
                    onKeyPress={(event) => PreventFirstZero(event)}
                    onChange={(e) => {
                      CategoryFormik.setFieldValue("unit", e.target.value);
                    }}
                  />
                  {CategoryFormik.touched.unit && CategoryFormik.errors.unit ? (
                    <div style={{ color: "red" }}>
                      {CategoryFormik.errors.unit}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Minimum Experience Required</Form.Label>
                  <Select
                    value={experience}
                    onChange={setExperience}
                    options={experienced}
                  />
                </Form.Group>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  value={location}
                  ref={ref}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col md={6} className="mb-3">
                <Form.Label>Sub-Category</Form.Label>
                <Form.Group>
                  <Select
                    placeholder={str?.title}
                    value={categories}
                    onChange={setCategories}
                    options={categoryList}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label>Select Sub-Category</Form.Label>
                <Form.Group>
                  <Select
                    placeholder={str?.title}
                    value={subCategories}
                    onChange={setSubCategories}
                    options={subCategoryList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3 d-flex justify-content-end">
              {SingleId === null ||
              SingleId?.length === 0 ||
              SingleId?.status === "pending" ? (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={buttonDisable}
                  className="mx-2"
                  onClick={() => {
                    {
                      id ? setActiveButton("update") : setActiveButton("add");
                    }
                  }}
                >
                  {id ? "Update Job" : "Post Job"}
                </Button>
              ) : (
                ""
              )}
              {id && activeForm == "Send Offer" && (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={buttonDisable}
                  onClick={() => {
                    setActiveButton("send offer");
                  }}
                >
                  Send Offer
                </Button>
              )}
              {id && activeForm !== "Send Offer" && (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={buttonDisable}
                  onClick={() => {
                    // setShowDefaultEmergency(true);
                    setIsPost(true);
                    // setPostJob(false);
                    setPostItem(true);
                    setActiveButton("repost");
                  }}
                >
                  Repost Job
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
