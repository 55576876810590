/* eslint-disable */
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Pagination,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonCard from "../../components/CommonCard";
import Navbar from "../../components/Navbar";
import NoRecordFound from "../../components/NoRecordFound";
import Spinner from "../../components/spinner";
import { getJobs, jobByIdSuccess } from "../../Redux/addJob/actions";
import { Routers } from "../../routes";
import AddCard from "../../components/addCard";
import { getCardDetails } from "../../Redux/settings/actions";
import { getCategoryList } from "../../Redux/Category/actions";

const Job = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state?.auth.Auther);
  const JobList = useSelector((state) => state?.addJob?.getJob);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [category, setCategory] = useState([]);
  const [loader, setLoader] = useState(true);
  const [limit] = useState("10");
  const [categoryType, setCategoryType] = useState("");
  const [categoryValue, setCategoryValue] = React.useState("");

  const handleClick = (event) => {
    const selectedCategory = CategoryList.find(
      (option) => option.title === event.target.value
    );
    setCategoryType(selectedCategory ? selectedCategory.id : "");
    setCategoryValue(event.target.value);
  };
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const cardDetail = useSelector(
    (state) => state?.PushNotification?.cardDetails
  );
  useEffect(() => {
    dispatch(getCardDetails(login?.id));
  }, []);
  const [addCard, setAddCard] = useState(false);
  const [addCardModal, setAddCardModal] = useState(false);
  useEffect(() => {
    if (cardDetail !== null) {
      setAddCard(false);
    } else {
      setAddCard(true);
    }
  }, [cardDetail]);
  const CategoryList = useSelector((state) => state?.Category?.getCategoryList);
  useEffect(() => {
    if (CategoryList !== undefined) {
      let array = [
        {
          id: "",
          value: "ALL",
          label: "All",
        },
      ];
      CategoryList.map((item) => {
        array.push({
          id: item?.id,
          value: item?.title,
          label: item?.title,
        });
      });
      setCategory(array);
    }
  }, [CategoryList]);
  const currencies = [
    {
      value: "all",
      label: "All Jobs",
    },
    {
      value: "completed",
      label: "Completed",
    },
    {
      value: "emergency",
      label: "Emergency",
    },
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "upcoming",
      label: "Upcoming",
    },
    {
      value: "inprogress",
      label: "Inprogress",
    },
    {
      value: "canceled",
      label: "cancelled",
    },
  ];
  if (login?.role?.name === "worker") {
    currencies.push(
      {
        value: "applied",
        label: "Applied",
      },
      {
        value: "job_offer",
        label: "Job Offer",
      }
    );
  }
  useEffect(
    (action) => {
      dispatch(
        getJobs({
          userId: login?.id,
          page: page,
          limit: limit,
          type: type,
          category: categoryType,
          setLoader: setLoader,
        })
      );
    },
    [page, limit, type, categoryType]
  );
  useEffect(() => {
    dispatch(
      getCategoryList({
        page: page,
        limit: limit,
        search: "",
        setLoader: setLoader,
      })
    );
  }, [page, limit]);
  const nextPage = () => {
    if (page < JobList?.pages) {
      setPage(page + 1);
    }
  };
  const previousPage = () => {
    if (1 > page) {
      setPage(page - 1);
    }
  };
  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= JobList?.pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => {
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <>
      <Navbar module={login.role.name === "customer" ? "My Jobs" : "My Gigs"} />
      <Row className="pt-2 pb-4">
        {loader ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex justify-content-between mt-0 mb-4 headerBorder">
              <Col lg={5} md={5}>
                <Form.Group>
                  <Form.Select
                    defaultValue="1"
                    label="Select"
                    value={type && type}
                    onChange={handleChange}
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={5} md={5}>
                <Form.Group>
                  <Form.Select
                    defaultValue="1"
                    label="Select"
                    value={categoryValue}
                    onChange={handleClick}
                  >
                    {category.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {login?.role.name === "customer" && (
                <span className="d-flex align-items-baseline mb-3">
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => {
                      // add ccheck here based on user role
                      addCard
                        ? setAddCardModal(true)
                        : navigate(Routers.CreateJob.path);
                      dispatch(jobByIdSuccess(null));
                    }}
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 12H8M12 8V12V8ZM12 12V16V12ZM12 12H16H12Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="white"
                        stroke-width="2"
                      />
                    </svg>
                    {"  "}
                    {"Add Job"}
                  </Button>
                </span>
              )}
            </div>
            {JobList?.jobs?.length > 0 ? (
              <>
                {JobList?.jobs?.map((item) => {
                  return (
                    <Col lg={2} md={4} sm={6} xs={12} className="pb-3 mt-3">
                      <CommonCard
                        img={item?.images}
                        name={item?.name ? item?.name : "N/A"}
                        // jobType={item?.jobType ? item?.jobType : "N/A"}
                        id={item.id}
                        item={item ? item : null}
                        rate={item?.rate ? item?.rate : "N/A"}
                        completed={"90"}
                        star={item?.rating}
                        myJobs={true}
                        page={page}
                        job="job"
                        limit={limit}
                        type={type}
                        category={categoryType}
                        favourite={item.isFavourite}
                      />
                    </Col>
                  );
                })}
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Nav>
                    <Pagination size={"sm"} className="mb-2 mb-lg-0">
                      <Pagination.Prev onClick={() => previousPage()}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </Pagination.Prev>
                      {paginationItems().length > 10 ? (
                        <>
                          {paginationItems().slice(0, 10)}
                          <Pagination.Ellipsis />
                          {paginationItems().slice(-10)}
                        </>
                      ) : (
                        paginationItems()
                      )}
                      <Pagination.Next onClick={() => nextPage()}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Pagination.Next>
                    </Pagination>
                  </Nav>
                  <small className="fw-bold">
                    Showing <b>{JobList?.jobs?.length}</b> out of{" "}
                    <b>{JobList?.total_jobs}</b> entries
                  </small>
                </Card.Footer>
              </>
            ) : (
              <NoRecordFound />
            )}
          </>
        )}
      </Row>
      <AddCard
        addCard={addCardModal}
        setAddCard={setAddCardModal}

        // onHide={() => setAddCard(true)
        // }
      />
    </>
  );
};

export default Job;
