export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGOUT_SUCCESS = "logout_success";
export const LOGIN_SUCCESS = "login_success";
export const FORGOT_PASSWORD = "forgot_password";
export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";
export const SET_LOADER = "set_loader";
export const UPDATE_PASSWORD = "update_password";
export const UPDATE_PASSWORD_SUCCESS = "update_password_success";
export const ADMIN_UPDATED_SUCCESS = "Amin_Updated_Success";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const LOGIN_FACEBOOK_SUCCESS = "LOGIN_FACEBOOK_success";
export const LOGIN_GOOGLE = "LOGIN_GOOGLE";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN_GOOGLE_success";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const UPDATE_AUTH_SUCCESS = "UPDATE_AUTH_success";
export const SIGN_UP = "SIGN_UP";
export const GOOGLE_SIGN_UP = "GOOGLE_SIGN_UP";
export const GOOGLE_SIGN_UP_SUCCESS = "GOOGLE_SIGN_UP_SUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_U_SUCCESS";
export const RESEND_OTP = "RESEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
