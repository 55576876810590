import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
const PrivateRoute = () => {
  const userDetail = useSelector((state) => state.auth); 
  console.log(userDetail, "private route");
  return userDetail?.token && userDetail?.isEmailVerified && userDetail?.isCompleteProfile ? (
    <>
      <Sidebar />
      <main className="content">
      <Outlet />
      </main>
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoute;