import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Snackbar, Typography } from "@mui/material"; // Import MUI components
import Select from "react-select"; // Import react-select
import Navbar from "../../components/Navbar";
import {
  getBusinessCategoryList,
} from "../../Redux/BusinessCategory/actions";
import { getCategoryList, updateUserSelectedCategoryList } from "../../Redux/Category/actions";
import Spinner from "../../components/spinner";


const BusinessCategories = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const allCategoryList = useSelector((state) => state?.Category?.getCategoryList);
  const CategoryData = useSelector((state) => state?.BusinessCategory?.getBusinessCategoryList);

  const [selectedMainCategory, setSelectedMainCategory] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar visibility

  useEffect(() => {
    const checkedMainCategories = allCategoryList?.filter(category =>
      CategoryData.some(cat => cat.mainCategoryId === category.id)
    ).map(category => ({ value: category.title, label: category.title }));
    setSelectedMainCategory(checkedMainCategories);
  }, [CategoryData, allCategoryList]);

  useEffect(() => {
    const checkedSubCategories = allCategoryList?.filter(cat =>
      selectedMainCategory.map(main => main.value).includes(cat.title)
    ).flatMap(cat =>
      cat.sub_Categories.filter(subcat =>
        CategoryData.some(catData => catData.categoriesId.includes(subcat.id))
      ).map(subcat => ({ value: subcat.title, label: subcat.title, image: subcat.categoryImg }))
    );
    setSelectedSubCategories(checkedSubCategories);
  }, [selectedMainCategory, allCategoryList, CategoryData]);

  const handleMainCategoryChange = (selectedOptions) => {
    setSelectedMainCategory(selectedOptions);
  };

  const handleSubCategoryChange = (selectedOptions) => {
    setSelectedSubCategories(selectedOptions);
  };

  const handleSubmit = () => {
    // Validation: Check if any main category is selected
    if (selectedMainCategory.length === 0) {
      setErrorMessage("Please select at least one main category.");
      setOpenSnackbar(true);
      return;
    }

    // Validation: Check if any subcategory is selected
    let hasError = false;
    const result = selectedMainCategory.map(mainCategory => {
      const mainCategoryData = allCategoryList.find(cat => cat.title === mainCategory.value);
      const categoriesId = mainCategoryData?.sub_Categories
        .filter(subcat => selectedSubCategories.map(sub => sub.value).includes(subcat.title))
        .map(subcat => subcat.id); // Get the IDs of selected subcategories

      // Check if categoriesId is empty
      if (!categoriesId || categoriesId.length === 0) {
        setErrorMessage(`Please select at least one subcategory for "${mainCategory.label}".`);
        setOpenSnackbar(true);
        hasError = true; // Indicate an error occurred
        return null; // Return null for this entry to indicate an error
      }

      return {
        mainCategoryId: mainCategoryData?.id, // Assuming main category has an id
        categoriesId: categoriesId // Ensure it's an array
      };
    }).filter(item => item !== null); // Filter out any null entries

    // Check if the result array is empty or if an error occurred
    if (result.length === 0 || hasError) {
      return; // Exit the function if no valid categories are found or an error occurred
    }
    dispatch(updateUserSelectedCategoryList({ data: result, setLoader: setLoader }));
  };

  useEffect(() => {
    dispatch(getBusinessCategoryList({ setLoader }));
    dispatch(getCategoryList({ setLoader }));
  }, [dispatch]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Navbar module={"Categories"} />
      {loader ? (
        <Spinner />
      ) : (
        <>
      <Typography variant="h5" sx={{ marginTop: "10px", textAlign: "center" }}>
        You can now select multiple main and sub categories.
      </Typography>
      <div style={{ marginTop: "10px" }}>
        <Typography variant="h6">Main Category</Typography>
        <Select
          isMulti
          options={allCategoryList?.map(category => ({ value: category.title, label: category.title }))}
          value={selectedMainCategory}
          onChange={handleMainCategoryChange}
          className="basic-multi-select"
          classNamePrefix="select"
          isLoading={loader}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <Typography variant="h6">Sub Category</Typography>
        <Select
          isMulti
          options={allCategoryList
            ?.filter(cat => selectedMainCategory.map(main => main.value).includes(cat.title))
            .flatMap(cat => cat.sub_Categories.map(subcat => {
              console.log(subcat, "subcat"); // Log the subcat
              return {
                value: subcat.title,
                label: subcat.title,
                image: subcat.categoryImg ? subcat.categoryImg : 'No Image', // Assuming subcat has an image property, otherwise show 'No Image'
              };
            }))}
          value={selectedSubCategories}
          onChange={handleSubCategoryChange}
          className="basic-multi-select"
          classNamePrefix="select"
          getOptionLabel={(option) => (
            <>
              <img src={option.image} alt={option.label} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
              {option.label}
            </>
          )} // Display image in the option label
          isLoading={loader}
        />
      </div>
      <Button variant="contained" sx={{ marginTop: "20px" }} onClick={handleSubmit}>Submit</Button>
      </>
      )}
      {/* Snackbar for error messages */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BusinessCategories;