import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Form,
  Modal,
} from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";

import { postLoghours } from "../../Redux/addJob/actions";
import { useDispatch } from "react-redux";

const AddLogHours = ({showLogHours, setShowLogHours}) => {
    const dispatch = useDispatch();
    
  const { jobId } = useParams();
  const [formData, setFormData] = useState({
    hours: "",
    minutes: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ ...formData, jobId });
    dispatch(postLoghours({ ...formData, jobId, setShowLogHours }))
  };

  const hoursOptions = Array.from({ length: 24 }, (_, i) => i+1);
  const minutesOptions = Array.from({ length: 60 }, (_, i) => i+1);

  return (
    <Container>
      <Modal
        as={Modal.Dialog}
        centered
        show={showLogHours}
        onHide={() => setShowLogHours(false)}
      >
        <Modal.Header>
          <Modal.Title className="h5">Log Hours</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => setShowLogHours(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Row>
                <Col lg={6} md={8} xs={12} className="pb-3 mb-3">
                  <Form.Group controlId="formHours">
                    <Form.Label>Hours</Form.Label>
                    <Form.Control
                      as="select"
                      name="hours"
                      value={formData.hours}
                      onChange={handleChange}
                    >
                      <option value="">Select hours</option>
                      {hoursOptions.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={6} md={8} xs={12} className="pb-3 mb-3">
                  <Form.Group controlId="formMinutes">
                    <Form.Label>Minutes</Form.Label>
                    <Form.Control
                      as="select"
                      name="minutes"
                      value={formData.minutes}
                      onChange={handleChange}
                    >
                      <option value="">Select minutes</option>
                      {minutesOptions.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit">
                Log Hours
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AddLogHours;
