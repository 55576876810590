import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardCheck,
  paymentAccountProvider,
} from "../../Redux/settings/actions";

const PaymentMethod = () => {
  const [loader, setLoader] = useState(false);
  const cardCheck = useSelector((state) => state?.PushNotification?.cardCheck);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.Auther);

  useEffect(() => {
    setLoader(true);
    dispatch(getCardCheck({ userId: user?.id, setLoader: setLoader }));
  }, [dispatch, user?.id, setLoader]);

  const handleConnectBankAccount = () => {
    setLoader(true);
    dispatch(
      paymentAccountProvider({ userId: user?.id, setLoader: setLoader })
    );
  };
  console.log(cardCheck, "cardCheckcardCheck");
  return (
    <>
      <Navbar module={"Payment Method"} />
      <Row>
        <Col xs={12} xl={12}>
          <Card.Body className="">
            <div className="border_bottom pb-2 mb-4">
              <Link className="fw-bold" to={`/add_card`}>
                <Card.Title className="text-primary">Add Card</Card.Title>
              </Link>
            </div>
            {cardCheck === null || cardCheck?.account_status === 0 ? (
              <div className="border_bottom pb-2 mb-4">
                <Link
                  className="fw-bold"
                  onClick={handleConnectBankAccount}
                  disabled={loader}
                >
                  <Card.Title className="text-primary">
                    Connect Bank Account
                  </Card.Title>
                </Link>
              </div>
            ) : (
              <>
                {cardCheck?.account_status === 1 ? (
                  <div className="border_bottom pb-2 mb-4">
                    <Card.Title className="text-primary">
                      Please wait for verification
                    </Card.Title>
                  </div>
                ) : (
                  <div className="border_bottom pb-2 mb-4">
                    <Card.Title className="text-primary">
                      Bank Account connected
                    </Card.Title>
                  </div>
                )}
              </>
            )}
            <div className="border_bottom pb-2 mb-4">
              <Link className="fw-bold" to={`/wallet`}>
                <Card.Title className="text-primary">Wallet</Card.Title>
              </Link>
            </div>
            <div className="border_bottom pb-2 mb-4">
              <Link className="fw-bold" to={`/transaction_history`}>
                <Card.Title className="text-primary">
                  Transaction History
                </Card.Title>
              </Link>
            </div>
          </Card.Body>
        </Col>
      </Row>
    </>
  );
};

export default PaymentMethod;
